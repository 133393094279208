/* tslint:disable */
/* eslint-disable */
/**
 * Standard Bots RO1 Robotics API
 * Standard Bots RO1 Robotics API.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@standardbots.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Rotational positions of arm joints
 * @export
 * @interface ArmJointRotations
 */
export interface ArmJointRotations {
    /**
     * 
     * @type {any}
     * @memberof ArmJointRotations
     */
    'joints': any;
}
/**
 * Move robot event when motion is canceled
 * @export
 * @interface ArmPositionUpdateCanceledEvent
 */
export interface ArmPositionUpdateCanceledEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateCanceledEvent
     */
    'kind'?: any;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateCanceledEvent
     */
    'reason'?: any;
}
/**
 * Event emitted by the move robot API
 * @export
 * @interface ArmPositionUpdateEvent
 */
export interface ArmPositionUpdateEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateEvent
     */
    'kind'?: ArmPositionUpdateEventKindEnum;
    /**
     * 
     * @type {ArmPositionUpdateFailureEvent}
     * @memberof ArmPositionUpdateEvent
     */
    'failure'?: ArmPositionUpdateFailureEvent;
    /**
     * 
     * @type {ArmPositionUpdateCanceledEvent}
     * @memberof ArmPositionUpdateEvent
     */
    'canceled'?: ArmPositionUpdateCanceledEvent;
    /**
     * 
     * @type {CombinedArmPosition}
     * @memberof ArmPositionUpdateEvent
     */
    'position'?: CombinedArmPosition;
}

export const ArmPositionUpdateEventKindEnum = {
    Success: 'success',
    Failure: 'failure',
    Canceled: 'canceled',
    Position: 'position',
    Planning: 'planning'
} as const;

export type ArmPositionUpdateEventKindEnum = typeof ArmPositionUpdateEventKindEnum[keyof typeof ArmPositionUpdateEventKindEnum];

/**
 * Move robot event when movement failed
 * @export
 * @interface ArmPositionUpdateFailureEvent
 */
export interface ArmPositionUpdateFailureEvent {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateFailureEvent
     */
    'kind': ArmPositionUpdateFailureEventKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateFailureEvent
     */
    'reason': any;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateFailureEvent
     */
    'internal_reason'?: any;
}

export const ArmPositionUpdateFailureEventKindEnum = {
    UnknownReason: 'motion_failed_unknown_reason',
    Collision: 'motion_failed_collision'
} as const;

export type ArmPositionUpdateFailureEventKindEnum = typeof ArmPositionUpdateFailureEventKindEnum[keyof typeof ArmPositionUpdateFailureEventKindEnum];

/**
 * Move the robot
 * @export
 * @interface ArmPositionUpdateRequest
 */
export interface ArmPositionUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'kind': ArmPositionUpdateRequestKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'tooltip_positions'?: any;
    /**
     * 
     * @type {PositionAndOrientation}
     * @memberof ArmPositionUpdateRequest
     */
    'tooltip_position'?: PositionAndOrientation;
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequest
     */
    'joint_rotations'?: any;
    /**
     * 
     * @type {ArmJointRotations}
     * @memberof ArmPositionUpdateRequest
     */
    'joint_rotation'?: ArmJointRotations;
    /**
     * 
     * @type {ArmPositionUpdateRequestResponseFormat}
     * @memberof ArmPositionUpdateRequest
     */
    'response'?: ArmPositionUpdateRequestResponseFormat;
}

export const ArmPositionUpdateRequestKindEnum = {
    TooltipPositions: 'tooltip_positions',
    TooltipPosition: 'tooltip_position',
    JointRotations: 'joint_rotations',
    JointRotation: 'joint_rotation'
} as const;

export type ArmPositionUpdateRequestKindEnum = typeof ArmPositionUpdateRequestKindEnum[keyof typeof ArmPositionUpdateRequestKindEnum];

/**
 * Specify how the response should be sent 
 * @export
 * @interface ArmPositionUpdateRequestResponseEventStreamDetails
 */
export interface ArmPositionUpdateRequestResponseEventStreamDetails {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequestResponseEventStreamDetails
     */
    'subscriptions': any;
}
/**
 * Specify how the response should be sent 
 * @export
 * @interface ArmPositionUpdateRequestResponseFormat
 */
export interface ArmPositionUpdateRequestResponseFormat {
    /**
     * 
     * @type {any}
     * @memberof ArmPositionUpdateRequestResponseFormat
     */
    'kind'?: ArmPositionUpdateRequestResponseFormatKindEnum;
    /**
     * 
     * @type {ArmPositionUpdateRequestResponseEventStreamDetails}
     * @memberof ArmPositionUpdateRequestResponseFormat
     */
    'event_stream'?: ArmPositionUpdateRequestResponseEventStreamDetails;
}

export const ArmPositionUpdateRequestResponseFormatKindEnum = {
    Sync: 'sync',
    EventStream: 'event_stream'
} as const;

export type ArmPositionUpdateRequestResponseFormatKindEnum = typeof ArmPositionUpdateRequestResponseFormatKindEnum[keyof typeof ArmPositionUpdateRequestResponseFormatKindEnum];

/**
 * State of the robot joint brakes. Each joint contains a brake which can be engaged when the robot is at a standstill to prevent the robot from moving. 
 * @export
 * @interface BrakesState
 */
export interface BrakesState {
    /**
     * 
     * @type {any}
     * @memberof BrakesState
     */
    'state': BrakesStateStateEnum;
}

export const BrakesStateStateEnum = {
    Engaged: 'engaged',
    Disengaged: 'disengaged'
} as const;

export type BrakesStateStateEnum = typeof BrakesStateStateEnum[keyof typeof BrakesStateStateEnum];

/**
 * Request for a single camera frame.
 * @export
 * @interface CameraFrameRequest
 */
export interface CameraFrameRequest {
    /**
     * 
     * @type {CameraSettings}
     * @memberof CameraFrameRequest
     */
    'camera_settings'?: CameraSettings;
}
/**
 * Intrinsic parameters of the camera.
 * @export
 * @interface CameraIntrinsics
 */
export interface CameraIntrinsics {
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'width'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'height'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'fx'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'fy'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'ppx'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'ppy'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraIntrinsics
     */
    'error'?: any;
}
/**
 * Response with intrinsic parameters of the camera.
 * @export
 * @interface CameraIntrinsicsResponse
 */
export interface CameraIntrinsicsResponse {
    /**
     * 
     * @type {CameraIntrinsics}
     * @memberof CameraIntrinsicsResponse
     */
    'intrinsics'?: CameraIntrinsics;
}
/**
 * Settings for the camera.
 * @export
 * @interface CameraSettings
 */
export interface CameraSettings {
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'brightness'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'contrast'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'exposure'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'sharpness'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'hue'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'whiteBalance'?: any;
    /**
     * 
     * @type {any}
     * @memberof CameraSettings
     */
    'autoWhiteBalance'?: any;
}
/**
 * Combined tooltip position and joint rotations defining the arm\'s position
 * @export
 * @interface CombinedArmPosition
 */
export interface CombinedArmPosition {
    /**
     * 
     * @type {any}
     * @memberof CombinedArmPosition
     */
    'joint_rotations': any;
    /**
     * 
     * @type {PositionAndOrientation}
     * @memberof CombinedArmPosition
     */
    'tooltip_position': PositionAndOrientation;
}
/**
 * Control the DH AG gripper (end effector) of the robot 
 * @export
 * @interface DHAGGripperCommandRequest
 */
export interface DHAGGripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof DHAGGripperCommandRequest
     */
    'target_diameter': any;
    /**
     * 
     * @type {any}
     * @memberof DHAGGripperCommandRequest
     */
    'target_force'?: any;
    /**
     * 
     * @type {any}
     * @memberof DHAGGripperCommandRequest
     */
    'target_speed'?: any;
}
/**
 * Configuration for DH AG Gripper
 * @export
 * @interface DHAGGripperConfiguration
 */
export interface DHAGGripperConfiguration {
    /**
     * 
     * @type {any}
     * @memberof DHAGGripperConfiguration
     */
    'diameter': any;
}
/**
 * Control the DH CGI gripper (end effector) of the robot 
 * @export
 * @interface DHCGIGripperCommandRequest
 */
export interface DHCGIGripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof DHCGIGripperCommandRequest
     */
    'target_diameter': any;
    /**
     * 
     * @type {any}
     * @memberof DHCGIGripperCommandRequest
     */
    'target_force'?: any;
    /**
     * 
     * @type {any}
     * @memberof DHCGIGripperCommandRequest
     */
    'target_speed'?: any;
}
/**
 * Configuration for DH CGI Gripper
 * @export
 * @interface DHCGIGripperConfiguration
 */
export interface DHCGIGripperConfiguration {
    /**
     * 
     * @type {any}
     * @memberof DHCGIGripperConfiguration
     */
    'diameter': any;
}
/**
 * Control the DH PGC gripper (end effector) of the robot 
 * @export
 * @interface DHPGCGripperCommandRequest
 */
export interface DHPGCGripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof DHPGCGripperCommandRequest
     */
    'target_diameter': any;
    /**
     * 
     * @type {any}
     * @memberof DHPGCGripperCommandRequest
     */
    'target_force'?: any;
    /**
     * 
     * @type {any}
     * @memberof DHPGCGripperCommandRequest
     */
    'target_speed'?: any;
}
/**
 * Configuration for DH PGC Gripper
 * @export
 * @interface DHPGCGripperConfiguration
 */
export interface DHPGCGripperConfiguration {
    /**
     * 
     * @type {any}
     * @memberof DHPGCGripperConfiguration
     */
    'diameter': any;
}
/**
 * Engage Emergency Stop. This will immediately stop the robot and prevent it from moving until the robot is unbraked. 
 * @export
 * @interface EngageEmergencyStopRequest
 */
export interface EngageEmergencyStopRequest {
    /**
     * 
     * @type {any}
     * @memberof EngageEmergencyStopRequest
     */
    'reason'?: any;
}
/**
 * Environment variables for a routine
 * @export
 * @interface EnvironmentVariable
 */
export interface EnvironmentVariable {
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'name'?: any;
    /**
     * 
     * @type {any}
     * @memberof EnvironmentVariable
     */
    'value'?: any;
}
/**
 * Error Response
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {any}
     * @memberof ErrorResponse
     */
    'error': ErrorResponseErrorEnum;
    /**
     * 
     * @type {any}
     * @memberof ErrorResponse
     */
    'message': any;
}

export const ErrorResponseErrorEnum = {
    AuthorizationRequired: 'authorization_required',
    RoutineMustBeRunning: 'routine_must_be_running',
    ApiControlRequired: 'api_control_required',
    RobotBrakesDisengageFailed: 'robot_brakes_disengage_failed',
    RobotBrakesEngageFailed: 'robot_brakes_engage_failed',
    RequestFailedValidation: 'request_failed_validation',
    RobotNotIdle: 'robot_not_idle',
    BrakesMustBeEngaged: 'brakes_must_be_engaged',
    BrakesMustBeDisengaged: 'brakes_must_be_disengaged',
    EquipmentNoMatching: 'equipment_no_matching',
    ServiceInitializing: 'service_initializing',
    CameraDisconnected: 'camera_disconnected',
    SettingsValidationError: 'settings_validation_error',
    SettingsTimeout: 'settings_timeout',
    InternalServerError: 'internal_server_error',
    RecoveryError: 'recovery_error',
    NotFound: 'not_found'
} as const;

export type ErrorResponseErrorEnum = typeof ErrorResponseErrorEnum[keyof typeof ErrorResponseErrorEnum];

/**
 * Reusable Abstraction for force units (eg force, torque) 
 * @export
 * @interface ForceUnit
 */
export interface ForceUnit {
    /**
     * 
     * @type {any}
     * @memberof ForceUnit
     */
    'unit_kind': ForceUnitUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof ForceUnit
     */
    'value'?: any;
}

export const ForceUnitUnitKindEnum = {
    Newtons: 'newtons',
    Pounds: 'pounds'
} as const;

export type ForceUnitUnitKindEnum = typeof ForceUnitUnitKindEnum[keyof typeof ForceUnitUnitKindEnum];

/**
 * Control the gripper (end effector) of the robot 
 * @export
 * @interface GripperCommandRequest
 */
export interface GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof GripperCommandRequest
     */
    'kind': GripperCommandRequestKindEnum;
    /**
     * 
     * @type {OnRobot2FG7GripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'onrobot_2fg7'?: OnRobot2FG7GripperCommandRequest;
    /**
     * 
     * @type {OnRobot2FG14GripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'onrobot_2fg14'?: OnRobot2FG14GripperCommandRequest;
    /**
     * 
     * @type {OnRobot3FG15GripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'onrobot_3fg15'?: OnRobot3FG15GripperCommandRequest;
    /**
     * 
     * @type {DHAGGripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'dh_ag'?: DHAGGripperCommandRequest;
    /**
     * 
     * @type {DHPGCGripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'dh_pgc'?: DHPGCGripperCommandRequest;
    /**
     * 
     * @type {DHCGIGripperCommandRequest}
     * @memberof GripperCommandRequest
     */
    'dh_cgi'?: DHCGIGripperCommandRequest;
}

export const GripperCommandRequestKindEnum = {
    Onrobot2fg7: 'onrobot_2fg7',
    Onrobot2fg14: 'onrobot_2fg14',
    Onrobot3fg15: 'onrobot_3fg15',
    DhAg: 'dh_ag',
    DhPgc: 'dh_pgc',
    DhCgi: 'dh_cgi',
    NoneConnected: 'none_connected'
} as const;

export type GripperCommandRequestKindEnum = typeof GripperCommandRequestKindEnum[keyof typeof GripperCommandRequestKindEnum];

/**
 * Configuration of gripper, also known as End Effector
 * @export
 * @interface GripperConfiguration
 */
export interface GripperConfiguration {
    /**
     * 
     * @type {any}
     * @memberof GripperConfiguration
     */
    'kind': GripperConfigurationKindEnum;
    /**
     * 
     * @type {DHAGGripperConfiguration}
     * @memberof GripperConfiguration
     */
    'dh_ag'?: DHAGGripperConfiguration;
    /**
     * 
     * @type {DHPGCGripperConfiguration}
     * @memberof GripperConfiguration
     */
    'dh_pgc'?: DHPGCGripperConfiguration;
    /**
     * 
     * @type {DHCGIGripperConfiguration}
     * @memberof GripperConfiguration
     */
    'dh_cgi'?: DHCGIGripperConfiguration;
}

export const GripperConfigurationKindEnum = {
    Onrobot2fg7: 'onrobot_2fg7',
    Onrobot2fg14: 'onrobot_2fg14',
    Onrobot3fg15: 'onrobot_3fg15',
    DhAg: 'dh_ag',
    DhPgc: 'dh_pgc',
    DhCgi: 'dh_cgi',
    NoneConnected: 'none_connected'
} as const;

export type GripperConfigurationKindEnum = typeof GripperConfigurationKindEnum[keyof typeof GripperConfigurationKindEnum];

/**
 * Response to a query for the current state of I/O.
 * @export
 * @interface IOStateResponse
 */
export interface IOStateResponse {
    /**
     * 
     * @type {any}
     * @memberof IOStateResponse
     */
    'state'?: any;
}
/**
 * Request to update the state of I/O to either start or stop.
 * @export
 * @interface IOStateUpdateRequest
 */
export interface IOStateUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof IOStateUpdateRequest
     */
    'state'?: any;
}
/**
 * State of a joint
 * @export
 * @interface JointState
 */
export interface JointState {
    /**
     * 
     * @type {any}
     * @memberof JointState
     */
    'braked'?: any;
    /**
     * 
     * @type {any}
     * @memberof JointState
     */
    'connectionStatus'?: JointStateConnectionStatusEnum;
    /**
     * 
     * @type {any}
     * @memberof JointState
     */
    'inCollision'?: any;
    /**
     * 
     * @type {any}
     * @memberof JointState
     */
    'disturbance'?: any;
}

export const JointStateConnectionStatusEnum = {
    Connected: 'connected',
    Disconnected: 'disconnected',
    Ready: 'ready'
} as const;

export type JointStateConnectionStatusEnum = typeof JointStateConnectionStatusEnum[keyof typeof JointStateConnectionStatusEnum];

/**
 * Response to a query for the current state of the joints
 * @export
 * @interface JointsStateResponse
 */
export interface JointsStateResponse {
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J0'?: JointState;
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J1'?: JointState;
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J2'?: JointState;
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J3'?: JointState;
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J4'?: JointState;
    /**
     * 
     * @type {JointState}
     * @memberof JointsStateResponse
     */
    'J5'?: JointState;
}
/**
 * Reusable Abstraction for linear units (eg distance, position, offset) 
 * @export
 * @interface LinearUnit
 */
export interface LinearUnit {
    /**
     * 
     * @type {any}
     * @memberof LinearUnit
     */
    'unit_kind': LinearUnitUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof LinearUnit
     */
    'value'?: any;
}

export const LinearUnitUnitKindEnum = {
    Millimeters: 'millimeters',
    Centimeters: 'centimeters',
    Meters: 'meters',
    Inches: 'inches',
    Feet: 'feet'
} as const;

export type LinearUnitUnitKindEnum = typeof LinearUnitUnitKindEnum[keyof typeof LinearUnitUnitKindEnum];

/**
 * Control the OnRobot 2FG14 gripper (end effector) of the robot 
 * @export
 * @interface OnRobot2FG14GripperCommandRequest
 */
export interface OnRobot2FG14GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG14GripperCommandRequest
     */
    'grip_direction': OnRobot2FG14GripperCommandRequestGripDirectionEnum;
    /**
     * 
     * @type {ForceUnit}
     * @memberof OnRobot2FG14GripperCommandRequest
     */
    'target_force'?: ForceUnit;
    /**
     * 
     * @type {LinearUnit}
     * @memberof OnRobot2FG14GripperCommandRequest
     */
    'target_grip_width'?: LinearUnit;
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG14GripperCommandRequest
     */
    'control_kind': OnRobot2FG14GripperCommandRequestControlKindEnum;
}

export const OnRobot2FG14GripperCommandRequestGripDirectionEnum = {
    Inward: 'inward',
    Outward: 'outward'
} as const;

export type OnRobot2FG14GripperCommandRequestGripDirectionEnum = typeof OnRobot2FG14GripperCommandRequestGripDirectionEnum[keyof typeof OnRobot2FG14GripperCommandRequestGripDirectionEnum];
export const OnRobot2FG14GripperCommandRequestControlKindEnum = {
    Move: 'move',
    ForceGrip: 'force_grip'
} as const;

export type OnRobot2FG14GripperCommandRequestControlKindEnum = typeof OnRobot2FG14GripperCommandRequestControlKindEnum[keyof typeof OnRobot2FG14GripperCommandRequestControlKindEnum];

/**
 * Control the OnRobot 2FG7 gripper (end effector) of the robot 
 * @export
 * @interface OnRobot2FG7GripperCommandRequest
 */
export interface OnRobot2FG7GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'grip_direction': OnRobot2FG7GripperCommandRequestGripDirectionEnum;
    /**
     * 
     * @type {ForceUnit}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'target_force'?: ForceUnit;
    /**
     * 
     * @type {LinearUnit}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'target_grip_width'?: LinearUnit;
    /**
     * 
     * @type {any}
     * @memberof OnRobot2FG7GripperCommandRequest
     */
    'control_kind': OnRobot2FG7GripperCommandRequestControlKindEnum;
}

export const OnRobot2FG7GripperCommandRequestGripDirectionEnum = {
    Inward: 'inward',
    Outward: 'outward'
} as const;

export type OnRobot2FG7GripperCommandRequestGripDirectionEnum = typeof OnRobot2FG7GripperCommandRequestGripDirectionEnum[keyof typeof OnRobot2FG7GripperCommandRequestGripDirectionEnum];
export const OnRobot2FG7GripperCommandRequestControlKindEnum = {
    Move: 'move',
    ForceGrip: 'force_grip'
} as const;

export type OnRobot2FG7GripperCommandRequestControlKindEnum = typeof OnRobot2FG7GripperCommandRequestControlKindEnum[keyof typeof OnRobot2FG7GripperCommandRequestControlKindEnum];

/**
 * Control the OnRobot 3FG15 gripper (end effector) of the robot 
 * @export
 * @interface OnRobot3FG15GripperCommandRequest
 */
export interface OnRobot3FG15GripperCommandRequest {
    /**
     * 
     * @type {any}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'grip_direction': OnRobot3FG15GripperCommandRequestGripDirectionEnum;
    /**
     * 
     * @type {ForceUnit}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'target_force'?: ForceUnit;
    /**
     * 
     * @type {LinearUnit}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'target_grip_diameter'?: LinearUnit;
    /**
     * 
     * @type {any}
     * @memberof OnRobot3FG15GripperCommandRequest
     */
    'control_kind': OnRobot3FG15GripperCommandRequestControlKindEnum;
}

export const OnRobot3FG15GripperCommandRequestGripDirectionEnum = {
    Inward: 'inward',
    Outward: 'outward'
} as const;

export type OnRobot3FG15GripperCommandRequestGripDirectionEnum = typeof OnRobot3FG15GripperCommandRequestGripDirectionEnum[keyof typeof OnRobot3FG15GripperCommandRequestGripDirectionEnum];
export const OnRobot3FG15GripperCommandRequestControlKindEnum = {
    Move: 'move',
    ForceGrip: 'force_grip',
    FlexibleForceGrip: 'flexible_force_grip'
} as const;

export type OnRobot3FG15GripperCommandRequestControlKindEnum = typeof OnRobot3FG15GripperCommandRequestControlKindEnum[keyof typeof OnRobot3FG15GripperCommandRequestControlKindEnum];

/**
 * Orientation of an object in 3D space
 * @export
 * @interface Orientation
 */
export interface Orientation {
    /**
     * 
     * @type {any}
     * @memberof Orientation
     */
    'kind': OrientationKindEnum;
    /**
     * 
     * @type {Quaternion}
     * @memberof Orientation
     */
    'quaternion'?: Quaternion;
}

export const OrientationKindEnum = {
    Quaternion: 'quaternion'
} as const;

export type OrientationKindEnum = typeof OrientationKindEnum[keyof typeof OrientationKindEnum];

/**
 * Common Pagination Metadata
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'total'?: any;
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'limit'?: any;
    /**
     * 
     * @type {any}
     * @memberof Pagination
     */
    'offset'?: any;
}
/**
 * Plane in 3D space
 * @export
 * @interface Plane
 */
export interface Plane {
    /**
     * 
     * @type {any}
     * @memberof Plane
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Plane
     */
    'name'?: any;
}
/**
 * Paginated response containing plane data
 * @export
 * @interface PlanesPaginatedResponse
 */
export interface PlanesPaginatedResponse {
    /**
     * 
     * @type {any}
     * @memberof PlanesPaginatedResponse
     */
    'items'?: any;
    /**
     * 
     * @type {Pagination}
     * @memberof PlanesPaginatedResponse
     */
    'pagination'?: Pagination;
}
/**
 * Request to play a routine
 * @export
 * @interface PlayRoutineRequest
 */
export interface PlayRoutineRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PlayRoutineRequest
     */
    'variables'?: { [key: string]: any; };
}
/**
 * Position of an object in 3D space. All empty values default to 0
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'unit_kind'?: PositionUnitKindEnum;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'x'?: any;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'y'?: any;
    /**
     * 
     * @type {any}
     * @memberof Position
     */
    'z'?: any;
}

export const PositionUnitKindEnum = {
    Millimeters: 'millimeters',
    Centimeters: 'centimeters',
    Meters: 'meters',
    Inches: 'inches',
    Feet: 'feet'
} as const;

export type PositionUnitKindEnum = typeof PositionUnitKindEnum[keyof typeof PositionUnitKindEnum];

/**
 * Position of the arm tooltip
 * @export
 * @interface PositionAndOrientation
 */
export interface PositionAndOrientation {
    /**
     * 
     * @type {any}
     * @memberof PositionAndOrientation
     */
    'axis_alignment'?: any;
    /**
     * 
     * @type {any}
     * @memberof PositionAndOrientation
     */
    'reference_frame'?: any;
    /**
     * 
     * @type {Position}
     * @memberof PositionAndOrientation
     */
    'position': Position;
    /**
     * 
     * @type {Orientation}
     * @memberof PositionAndOrientation
     */
    'orientation': Orientation;
}
/**
 * Quaternion orientation
 * @export
 * @interface Quaternion
 */
export interface Quaternion {
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'x'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'y'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'z'?: any;
    /**
     * 
     * @type {any}
     * @memberof Quaternion
     */
    'w'?: any;
}
/**
 * Response to a query for the current state of ROS control.
 * @export
 * @interface ROSControlStateResponse
 */
export interface ROSControlStateResponse {
    /**
     * 
     * @type {any}
     * @memberof ROSControlStateResponse
     */
    'state'?: ROSControlStateResponseStateEnum;
}

export const ROSControlStateResponseStateEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ROSControlStateResponseStateEnum = typeof ROSControlStateResponseStateEnum[keyof typeof ROSControlStateResponseStateEnum];

/**
 * Request to update the state of direct ROS control.
 * @export
 * @interface ROSControlUpdateRequest
 */
export interface ROSControlUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof ROSControlUpdateRequest
     */
    'action'?: ROSControlUpdateRequestActionEnum;
}

export const ROSControlUpdateRequestActionEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ROSControlUpdateRequestActionEnum = typeof ROSControlUpdateRequestActionEnum[keyof typeof ROSControlUpdateRequestActionEnum];

/**
 * Definition of Robot Control State, which tells you which service or system is controlling the Robot
 * @export
 * @interface RobotControlMode
 */
export interface RobotControlMode {
    /**
     * 
     * @type {any}
     * @memberof RobotControlMode
     */
    'kind'?: RobotControlModeKindEnum;
}

export const RobotControlModeKindEnum = {
    Api: 'api',
    RoutineEditor: 'routine_editor'
} as const;

export type RobotControlModeKindEnum = typeof RobotControlModeKindEnum[keyof typeof RobotControlModeKindEnum];

/**
 * Robot Routine containing steps to automate robot movement and operations
 * @export
 * @interface Routine
 */
export interface Routine {
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'name'?: any;
    /**
     * 
     * @type {any}
     * @memberof Routine
     */
    'environment_variables'?: any;
}
/**
 * Paginated response containing routine data
 * @export
 * @interface RoutinesPaginatedResponse
 */
export interface RoutinesPaginatedResponse {
    /**
     * 
     * @type {any}
     * @memberof RoutinesPaginatedResponse
     */
    'items'?: any;
    /**
     * 
     * @type {Pagination}
     * @memberof RoutinesPaginatedResponse
     */
    'pagination'?: Pagination;
}
/**
 * Runtime Variable state
 * @export
 * @interface RuntimeVariable
 */
export interface RuntimeVariable {
    /**
     * 
     * @type {any}
     * @memberof RuntimeVariable
     */
    'value'?: any;
}
/**
 * Request to convert speech to text.
 * @export
 * @interface SpeechToTextRequest
 */
export interface SpeechToTextRequest {
    /**
     * 
     * @type {any}
     * @memberof SpeechToTextRequest
     */
    'encoded_audio_data'?: any;
}
/**
 * Status Health Response
 * @export
 * @interface StatusHealthResponse
 */
export interface StatusHealthResponse {
    /**
     * 
     * @type {any}
     * @memberof StatusHealthResponse
     */
    'health'?: StatusHealthResponseHealthEnum;
    /**
     * 
     * @type {StatusVersionData}
     * @memberof StatusHealthResponse
     */
    'build'?: StatusVersionData;
}

export const StatusHealthResponseHealthEnum = {
    Ok: 'ok',
    Warning: 'warning',
    Error: 'error'
} as const;

export type StatusHealthResponseHealthEnum = typeof StatusHealthResponseHealthEnum[keyof typeof StatusHealthResponseHealthEnum];

/**
 * Version Data
 * @export
 * @interface StatusVersionData
 */
export interface StatusVersionData {
    /**
     * 
     * @type {any}
     * @memberof StatusVersionData
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof StatusVersionData
     */
    'name'?: any;
}
/**
 * Response to a query for the current state of teleoperation.
 * @export
 * @interface TeleopStateResponse
 */
export interface TeleopStateResponse {
    /**
     * 
     * @type {any}
     * @memberof TeleopStateResponse
     */
    'state'?: TeleopStateResponseStateEnum;
}

export const TeleopStateResponseStateEnum = {
    WaitForTeleop: 'wait_for_teleop',
    Teleop: 'teleop',
    Stopping: 'stopping',
    Stopped: 'stopped',
    Moving: 'moving',
    Unfrozen: 'unfrozen'
} as const;

export type TeleopStateResponseStateEnum = typeof TeleopStateResponseStateEnum[keyof typeof TeleopStateResponseStateEnum];

/**
 * Request to update the state of teleoperation to either start or stop.
 * @export
 * @interface TeleopStateUpdateRequest
 */
export interface TeleopStateUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof TeleopStateUpdateRequest
     */
    'action'?: any;
}
/**
 * Request to convert text to a skill.
 * @export
 * @interface TextToSkillRequest
 */
export interface TextToSkillRequest {
    /**
     * 
     * @type {any}
     * @memberof TextToSkillRequest
     */
    'text'?: any;
    /**
     * 
     * @type {any}
     * @memberof TextToSkillRequest
     */
    'skills'?: any;
}

/**
 * CameraDataApi - axios parameter creator
 * @export
 */
export const CameraDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the intrinsic parameters for the color camera.
         * @summary Get Camera Intrinsics (Color)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraIntrinsicsColor: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/camera/intrinsics/rgb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get Camera Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraStream: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/camera/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get RGB Frame
         * @param {CameraFrameRequest} [cameraFrameRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorFrame: async (cameraFrameRequest?: CameraFrameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/camera/frame/rgb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraFrameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraDataApi - functional programming interface
 * @export
 */
export const CameraDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the intrinsic parameters for the color camera.
         * @summary Get Camera Intrinsics (Color)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraIntrinsicsColor(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraIntrinsicsColor(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CameraDataApi.getCameraIntrinsicsColor']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get Camera Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraStream(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraStream(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CameraDataApi.getCameraStream']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get RGB Frame
         * @param {CameraFrameRequest} [cameraFrameRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColorFrame(cameraFrameRequest?: CameraFrameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColorFrame(cameraFrameRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CameraDataApi.getColorFrame']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CameraDataApi - factory interface
 * @export
 */
export const CameraDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraDataApiFp(configuration)
    return {
        /**
         * Retrieve the intrinsic parameters for the color camera.
         * @summary Get Camera Intrinsics (Color)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraIntrinsicsColor(options?: any): AxiosPromise<void> {
            return localVarFp.getCameraIntrinsicsColor(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get Camera Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraStream(options?: any): AxiosPromise<void> {
            return localVarFp.getCameraStream(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the latest RGB frame from the camera.
         * @summary Get RGB Frame
         * @param {CameraFrameRequest} [cameraFrameRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColorFrame(cameraFrameRequest?: CameraFrameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.getColorFrame(cameraFrameRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraDataApi - object-oriented interface
 * @export
 * @class CameraDataApi
 * @extends {BaseAPI}
 */
export class CameraDataApi extends BaseAPI {
    /**
     * Retrieve the intrinsic parameters for the color camera.
     * @summary Get Camera Intrinsics (Color)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraDataApi
     */
    public getCameraIntrinsicsColor(options?: AxiosRequestConfig) {
        return CameraDataApiFp(this.configuration).getCameraIntrinsicsColor(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the latest RGB frame from the camera.
     * @summary Get Camera Stream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraDataApi
     */
    public getCameraStream(options?: AxiosRequestConfig) {
        return CameraDataApiFp(this.configuration).getCameraStream(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the latest RGB frame from the camera.
     * @summary Get RGB Frame
     * @param {CameraFrameRequest} [cameraFrameRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraDataApi
     */
    public getColorFrame(cameraFrameRequest?: CameraFrameRequest, options?: AxiosRequestConfig) {
        return CameraDataApiFp(this.configuration).getColorFrame(cameraFrameRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CameraSettingsApi - axios parameter creator
 * @export
 */
export const CameraSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Set the camera settings.
         * @summary Set Camera Settings
         * @param {CameraSettings} [cameraSettings] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraSettings: async (cameraSettings?: CameraSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/camera/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cameraSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CameraSettingsApi - functional programming interface
 * @export
 */
export const CameraSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CameraSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Set the camera settings.
         * @summary Set Camera Settings
         * @param {CameraSettings} [cameraSettings] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCameraSettings(cameraSettings?: CameraSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCameraSettings(cameraSettings, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CameraSettingsApi.setCameraSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CameraSettingsApi - factory interface
 * @export
 */
export const CameraSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CameraSettingsApiFp(configuration)
    return {
        /**
         * Set the camera settings.
         * @summary Set Camera Settings
         * @param {CameraSettings} [cameraSettings] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraSettings(cameraSettings?: CameraSettings, options?: any): AxiosPromise<void> {
            return localVarFp.setCameraSettings(cameraSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CameraSettingsApi - object-oriented interface
 * @export
 * @class CameraSettingsApi
 * @extends {BaseAPI}
 */
export class CameraSettingsApi extends BaseAPI {
    /**
     * Set the camera settings.
     * @summary Set Camera Settings
     * @param {CameraSettings} [cameraSettings] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CameraSettingsApi
     */
    public setCameraSettings(cameraSettings?: CameraSettings, options?: AxiosRequestConfig) {
        return CameraSettingsApiFp(this.configuration).setCameraSettings(cameraSettings, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatGPTDataApi - axios parameter creator
 * @export
 */
export const ChatGPTDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Convert speech to text.
         * @summary Speech to Text
         * @param {SpeechToTextRequest} [speechToTextRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechToText: async (speechToTextRequest?: SpeechToTextRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal-only/speech-to-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speechToTextRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Convert text to a skill.
         * @summary Text to Skill
         * @param {TextToSkillRequest} [textToSkillRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textToSkill: async (textToSkillRequest?: TextToSkillRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal-only/text-to-skill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textToSkillRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatGPTDataApi - functional programming interface
 * @export
 */
export const ChatGPTDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatGPTDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Convert speech to text.
         * @summary Speech to Text
         * @param {SpeechToTextRequest} [speechToTextRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async speechToText(speechToTextRequest?: SpeechToTextRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.speechToText(speechToTextRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChatGPTDataApi.speechToText']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Convert text to a skill.
         * @summary Text to Skill
         * @param {TextToSkillRequest} [textToSkillRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async textToSkill(textToSkillRequest?: TextToSkillRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.textToSkill(textToSkillRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChatGPTDataApi.textToSkill']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChatGPTDataApi - factory interface
 * @export
 */
export const ChatGPTDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatGPTDataApiFp(configuration)
    return {
        /**
         * Convert speech to text.
         * @summary Speech to Text
         * @param {SpeechToTextRequest} [speechToTextRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        speechToText(speechToTextRequest?: SpeechToTextRequest, options?: any): AxiosPromise<void> {
            return localVarFp.speechToText(speechToTextRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Convert text to a skill.
         * @summary Text to Skill
         * @param {TextToSkillRequest} [textToSkillRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        textToSkill(textToSkillRequest?: TextToSkillRequest, options?: any): AxiosPromise<void> {
            return localVarFp.textToSkill(textToSkillRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatGPTDataApi - object-oriented interface
 * @export
 * @class ChatGPTDataApi
 * @extends {BaseAPI}
 */
export class ChatGPTDataApi extends BaseAPI {
    /**
     * Convert speech to text.
     * @summary Speech to Text
     * @param {SpeechToTextRequest} [speechToTextRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatGPTDataApi
     */
    public speechToText(speechToTextRequest?: SpeechToTextRequest, options?: AxiosRequestConfig) {
        return ChatGPTDataApiFp(this.configuration).speechToText(speechToTextRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Convert text to a skill.
     * @summary Text to Skill
     * @param {TextToSkillRequest} [textToSkillRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatGPTDataApi
     */
    public textToSkill(textToSkillRequest?: TextToSkillRequest, options?: AxiosRequestConfig) {
        return ChatGPTDataApiFp(this.configuration).textToSkill(textToSkillRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlGripper: async (gripperCommandRequest?: GripperCommandRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/end-effector/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gripperCommandRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGripperConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/equipment/end-effector/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlGripper(gripperCommandRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EquipmentApi.controlGripper']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGripperConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GripperConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGripperConfiguration(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EquipmentApi.getGripperConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
         * @summary Control Gripper
         * @param {GripperCommandRequest} [gripperCommandRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: any): AxiosPromise<void> {
            return localVarFp.controlGripper(gripperCommandRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current gripper configuration 
         * @summary Get Gripper Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGripperConfiguration(options?: any): AxiosPromise<GripperConfiguration> {
            return localVarFp.getGripperConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * Send commands to control the Gripper (End Effector) of the robot. The gripper can be any of Standard Bots supported grippers. 
     * @summary Control Gripper
     * @param {GripperCommandRequest} [gripperCommandRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public controlGripper(gripperCommandRequest?: GripperCommandRequest, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).controlGripper(gripperCommandRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current gripper configuration 
     * @summary Get Gripper Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getGripperConfiguration(options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getGripperConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralJointsApi - axios parameter creator
 * @export
 */
export const GeneralJointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves information about the state of each joint
         * @summary Get Joints State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJointsState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/joints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralJointsApi - functional programming interface
 * @export
 */
export const GeneralJointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralJointsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves information about the state of each joint
         * @summary Get Joints State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJointsState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JointsStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJointsState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeneralJointsApi.getJointsState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GeneralJointsApi - factory interface
 * @export
 */
export const GeneralJointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralJointsApiFp(configuration)
    return {
        /**
         * Retrieves information about the state of each joint
         * @summary Get Joints State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJointsState(options?: any): AxiosPromise<JointsStateResponse> {
            return localVarFp.getJointsState(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralJointsApi - object-oriented interface
 * @export
 * @class GeneralJointsApi
 * @extends {BaseAPI}
 */
export class GeneralJointsApi extends BaseAPI {
    /**
     * Retrieves information about the state of each joint
     * @summary Get Joints State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralJointsApi
     */
    public getJointsState(options?: AxiosRequestConfig) {
        return GeneralJointsApiFp(this.configuration).getJointsState(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IOControlApi - axios parameter creator
 * @export
 */
export const IOControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the state of I/O based on the provided action (\'high\' or \'low\').
         * @summary Change I/O State
         * @param {IOStateUpdateRequest} [iOStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIoState: async (iOStateUpdateRequest?: IOStateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/io`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOStateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IOControlApi - functional programming interface
 * @export
 */
export const IOControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IOControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the state of I/O based on the provided action (\'high\' or \'low\').
         * @summary Change I/O State
         * @param {IOStateUpdateRequest} [iOStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIoState(iOStateUpdateRequest?: IOStateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIoState(iOStateUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IOControlApi.updateIoState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IOControlApi - factory interface
 * @export
 */
export const IOControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IOControlApiFp(configuration)
    return {
        /**
         * Updates the state of I/O based on the provided action (\'high\' or \'low\').
         * @summary Change I/O State
         * @param {IOStateUpdateRequest} [iOStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIoState(iOStateUpdateRequest?: IOStateUpdateRequest, options?: any): AxiosPromise<IOStateResponse> {
            return localVarFp.updateIoState(iOStateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IOControlApi - object-oriented interface
 * @export
 * @class IOControlApi
 * @extends {BaseAPI}
 */
export class IOControlApi extends BaseAPI {
    /**
     * Updates the state of I/O based on the provided action (\'high\' or \'low\').
     * @summary Change I/O State
     * @param {IOStateUpdateRequest} [iOStateUpdateRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IOControlApi
     */
    public updateIoState(iOStateUpdateRequest?: IOStateUpdateRequest, options?: AxiosRequestConfig) {
        return IOControlApiFp(this.configuration).updateIoState(iOStateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IOStatusApi - axios parameter creator
 * @export
 */
export const IOStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the current state of I/O.
         * @summary Get I/O State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIoState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/io`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IOStatusApi - functional programming interface
 * @export
 */
export const IOStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IOStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the current state of I/O.
         * @summary Get I/O State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIoState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIoState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IOStatusApi.getIoState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IOStatusApi - factory interface
 * @export
 */
export const IOStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IOStatusApiFp(configuration)
    return {
        /**
         * Retrieves the current state of I/O.
         * @summary Get I/O State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIoState(options?: any): AxiosPromise<IOStateResponse> {
            return localVarFp.getIoState(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IOStatusApi - object-oriented interface
 * @export
 * @class IOStatusApi
 * @extends {BaseAPI}
 */
export class IOStatusApi extends BaseAPI {
    /**
     * Retrieves the current state of I/O.
     * @summary Get I/O State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IOStatusApi
     */
    public getIoState(options?: AxiosRequestConfig) {
        return IOStatusApiFp(this.configuration).getIoState(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MovementBrakesApi - axios parameter creator
 * @export
 */
export const MovementBrakesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageEmergencyStop: async (engageEmergencyStopRequest?: EngageEmergencyStopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes/emergency-stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(engageEmergencyStopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrakesState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBrakesState: async (brakesState?: BrakesState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/brakes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brakesState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovementBrakesApi - functional programming interface
 * @export
 */
export const MovementBrakesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovementBrakesApiAxiosParamCreator(configuration)
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.engageEmergencyStop(engageEmergencyStopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.engageEmergencyStop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrakesState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrakesState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrakesState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.getBrakesState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBrakesState(brakesState?: BrakesState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrakesState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setBrakesState(brakesState, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementBrakesApi.setBrakesState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MovementBrakesApi - factory interface
 * @export
 */
export const MovementBrakesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovementBrakesApiFp(configuration)
    return {
        /**
         * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
         * @summary Engage Emergency Stop
         * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: any): AxiosPromise<void> {
            return localVarFp.engageEmergencyStop(engageEmergencyStopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current state of the robot brakes 
         * @summary Get Robot Brakes State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrakesState(options?: any): AxiosPromise<BrakesState> {
            return localVarFp.getBrakesState(options).then((request) => request(axios, basePath));
        },
        /**
         * Control Joint Brakes in the robot 
         * @summary Control Robot Brakes
         * @param {BrakesState} [brakesState] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBrakesState(brakesState?: BrakesState, options?: any): AxiosPromise<BrakesState> {
            return localVarFp.setBrakesState(brakesState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovementBrakesApi - object-oriented interface
 * @export
 * @class MovementBrakesApi
 * @extends {BaseAPI}
 */
export class MovementBrakesApi extends BaseAPI {
    /**
     * Engage Emergency braking system. > **⚠️ Warning:** This will immediately stop the robot and may cause damage to the robot or surrounding environment. 
     * @summary Engage Emergency Stop
     * @param {EngageEmergencyStopRequest} [engageEmergencyStopRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public engageEmergencyStop(engageEmergencyStopRequest?: EngageEmergencyStopRequest, options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).engageEmergencyStop(engageEmergencyStopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current state of the robot brakes 
     * @summary Get Robot Brakes State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public getBrakesState(options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).getBrakesState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control Joint Brakes in the robot 
     * @summary Control Robot Brakes
     * @param {BrakesState} [brakesState] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementBrakesApi
     */
    public setBrakesState(brakesState?: BrakesState, options?: AxiosRequestConfig) {
        return MovementBrakesApiFp(this.configuration).setBrakesState(brakesState, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MovementPositionApi - axios parameter creator
 * @export
 */
export const MovementPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArmPosition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/position/arm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArmPosition: async (armPositionUpdateRequest?: ArmPositionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/position/arm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(armPositionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovementPositionApi - functional programming interface
 * @export
 */
export const MovementPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovementPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArmPosition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedArmPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArmPosition(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementPositionApi.getArmPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArmPositionUpdateEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setArmPosition(armPositionUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MovementPositionApi.setArmPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MovementPositionApi - factory interface
 * @export
 */
export const MovementPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovementPositionApiFp(configuration)
    return {
        /**
         * Get the current position of the robot arm
         * @summary Get Arm Position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArmPosition(options?: any): AxiosPromise<CombinedArmPosition> {
            return localVarFp.getArmPosition(options).then((request) => request(axios, basePath));
        },
        /**
         * Control the position of the RO1 Robot arm. 
         * @summary Set Arm Position (Move)
         * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: any): AxiosPromise<ArmPositionUpdateEvent> {
            return localVarFp.setArmPosition(armPositionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovementPositionApi - object-oriented interface
 * @export
 * @class MovementPositionApi
 * @extends {BaseAPI}
 */
export class MovementPositionApi extends BaseAPI {
    /**
     * Get the current position of the robot arm
     * @summary Get Arm Position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementPositionApi
     */
    public getArmPosition(options?: AxiosRequestConfig) {
        return MovementPositionApiFp(this.configuration).getArmPosition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Control the position of the RO1 Robot arm. 
     * @summary Set Arm Position (Move)
     * @param {ArmPositionUpdateRequest} [armPositionUpdateRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementPositionApi
     */
    public setArmPosition(armPositionUpdateRequest?: ArmPositionUpdateRequest, options?: AxiosRequestConfig) {
        return MovementPositionApiFp(this.configuration).setArmPosition(armPositionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ROSControlApi - axios parameter creator
 * @export
 */
export const ROSControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the state of ROS control based on the provided action (\'enable\' or \'disable\').
         * @summary Change ROS Control State
         * @param {ROSControlUpdateRequest} [rOSControlUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRosControlState: async (rOSControlUpdateRequest?: ROSControlUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/ros/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rOSControlUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ROSControlApi - functional programming interface
 * @export
 */
export const ROSControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ROSControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the state of ROS control based on the provided action (\'enable\' or \'disable\').
         * @summary Change ROS Control State
         * @param {ROSControlUpdateRequest} [rOSControlUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRosControlState(rOSControlUpdateRequest?: ROSControlUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ROSControlStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRosControlState(rOSControlUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ROSControlApi.updateRosControlState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ROSControlApi - factory interface
 * @export
 */
export const ROSControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ROSControlApiFp(configuration)
    return {
        /**
         * Updates the state of ROS control based on the provided action (\'enable\' or \'disable\').
         * @summary Change ROS Control State
         * @param {ROSControlUpdateRequest} [rOSControlUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRosControlState(rOSControlUpdateRequest?: ROSControlUpdateRequest, options?: any): AxiosPromise<ROSControlStateResponse> {
            return localVarFp.updateRosControlState(rOSControlUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ROSControlApi - object-oriented interface
 * @export
 * @class ROSControlApi
 * @extends {BaseAPI}
 */
export class ROSControlApi extends BaseAPI {
    /**
     * Updates the state of ROS control based on the provided action (\'enable\' or \'disable\').
     * @summary Change ROS Control State
     * @param {ROSControlUpdateRequest} [rOSControlUpdateRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ROSControlApi
     */
    public updateRosControlState(rOSControlUpdateRequest?: ROSControlUpdateRequest, options?: AxiosRequestConfig) {
        return ROSControlApiFp(this.configuration).updateRosControlState(rOSControlUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ROSStatusApi - axios parameter creator
 * @export
 */
export const ROSStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the current state of ROS control.
         * @summary Get ROS Control State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRosControlState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/ros/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ROSStatusApi - functional programming interface
 * @export
 */
export const ROSStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ROSStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the current state of ROS control.
         * @summary Get ROS Control State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRosControlState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ROSControlStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRosControlState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ROSStatusApi.getRosControlState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ROSStatusApi - factory interface
 * @export
 */
export const ROSStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ROSStatusApiFp(configuration)
    return {
        /**
         * Retrieves the current state of ROS control.
         * @summary Get ROS Control State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRosControlState(options?: any): AxiosPromise<ROSControlStateResponse> {
            return localVarFp.getRosControlState(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ROSStatusApi - object-oriented interface
 * @export
 * @class ROSStatusApi
 * @extends {BaseAPI}
 */
export class ROSStatusApi extends BaseAPI {
    /**
     * Retrieves the current state of ROS control.
     * @summary Get ROS Control State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ROSStatusApi
     */
    public getRosControlState(options?: AxiosRequestConfig) {
        return ROSStatusApiFp(this.configuration).getRosControlState(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecoveryRecoverApi - axios parameter creator
 * @export
 */
export const RecoveryRecoverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Recovers the robot from a fault state. 
         * @summary Recover the robot from a fault
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recover: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recovery/recover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecoveryRecoverApi - functional programming interface
 * @export
 */
export const RecoveryRecoverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecoveryRecoverApiAxiosParamCreator(configuration)
    return {
        /**
         * Recovers the robot from a fault state. 
         * @summary Recover the robot from a fault
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recover(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recover(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RecoveryRecoverApi.recover']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RecoveryRecoverApi - factory interface
 * @export
 */
export const RecoveryRecoverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecoveryRecoverApiFp(configuration)
    return {
        /**
         * Recovers the robot from a fault state. 
         * @summary Recover the robot from a fault
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recover(options?: any): AxiosPromise<void> {
            return localVarFp.recover(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecoveryRecoverApi - object-oriented interface
 * @export
 * @class RecoveryRecoverApi
 * @extends {BaseAPI}
 */
export class RecoveryRecoverApi extends BaseAPI {
    /**
     * Recovers the robot from a fault state. 
     * @summary Recover the robot from a fault
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecoveryRecoverApi
     */
    public recover(options?: AxiosRequestConfig) {
        return RecoveryRecoverApiFp(this.configuration).recover(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoutineEditorRoutinesApi - axios parameter creator
 * @export
 */
export const RoutineEditorRoutinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesList: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/routine-editor/routines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesLoad: async (routineId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesLoad', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPause: async (routineId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesPause', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}/pause`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPlay: async (routineId: string, playRoutineRequest?: PlayRoutineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routineId' is not null or undefined
            assertParamExists('routineEditorRoutinesPlay', 'routineId', routineId)
            const localVarPath = `/api/v1/routine-editor/routines/{routine_id}/play`
                .replace(`{${"routine_id"}}`, encodeURIComponent(String(routineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playRoutineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesStop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/routine-editor/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutineEditorRoutinesApi - functional programming interface
 * @export
 */
export const RoutineEditorRoutinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutineEditorRoutinesApiAxiosParamCreator(configuration)
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesList(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutinesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesList(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesLoad(routineId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Routine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesLoad(routineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesLoad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesPause(routineId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesPause(routineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesPause']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesPlay(routineId, playRoutineRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesPlay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorRoutinesStop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorRoutinesStop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorRoutinesApi.routineEditorRoutinesStop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoutineEditorRoutinesApi - factory interface
 * @export
 */
export const RoutineEditorRoutinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutineEditorRoutinesApiFp(configuration)
    return {
        /**
         * List routines defined in Routine Editor UI
         * @summary List Routines
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesList(limit?: number, offset?: number, options?: any): AxiosPromise<RoutinesPaginatedResponse> {
            return localVarFp.routineEditorRoutinesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get routine data by ID
         * @summary Get Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesLoad(routineId: string, options?: any): AxiosPromise<Routine> {
            return localVarFp.routineEditorRoutinesLoad(routineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Pause a routine
         * @summary Pause Routine
         * @param {string} routineId Routine ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPause(routineId: string, options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesPause(routineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Play a routine
         * @summary Play Routine
         * @param {string} routineId Routine ID
         * @param {PlayRoutineRequest} [playRoutineRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesPlay(routineId, playRoutineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stop running routine and all ongoing motions
         * @summary Stop Routine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorRoutinesStop(options?: any): AxiosPromise<void> {
            return localVarFp.routineEditorRoutinesStop(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutineEditorRoutinesApi - object-oriented interface
 * @export
 * @class RoutineEditorRoutinesApi
 * @extends {BaseAPI}
 */
export class RoutineEditorRoutinesApi extends BaseAPI {
    /**
     * List routines defined in Routine Editor UI
     * @summary List Routines
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesList(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get routine data by ID
     * @summary Get Routine
     * @param {string} routineId Routine ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesLoad(routineId: string, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesLoad(routineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pause a routine
     * @summary Pause Routine
     * @param {string} routineId Routine ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesPause(routineId: string, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesPause(routineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Play a routine
     * @summary Play Routine
     * @param {string} routineId Routine ID
     * @param {PlayRoutineRequest} [playRoutineRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesPlay(routineId: string, playRoutineRequest?: PlayRoutineRequest, options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesPlay(routineId, playRoutineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stop running routine and all ongoing motions
     * @summary Stop Routine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorRoutinesApi
     */
    public routineEditorRoutinesStop(options?: AxiosRequestConfig) {
        return RoutineEditorRoutinesApiFp(this.configuration).routineEditorRoutinesStop(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoutineEditorVariablesApi - axios parameter creator
 * @export
 */
export const RoutineEditorVariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesLoad: async (variableName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableName' is not null or undefined
            assertParamExists('routineEditorVariablesLoad', 'variableName', variableName)
            const localVarPath = `/api/v1/routine-editor/variables/{variable_name}`
                .replace(`{${"variable_name"}}`, encodeURIComponent(String(variableName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesUpdate: async (variableName: string, runtimeVariable?: RuntimeVariable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableName' is not null or undefined
            assertParamExists('routineEditorVariablesUpdate', 'variableName', variableName)
            const localVarPath = `/api/v1/routine-editor/variables/{variable_name}`
                .replace(`{${"variable_name"}}`, encodeURIComponent(String(variableName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runtimeVariable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutineEditorVariablesApi - functional programming interface
 * @export
 */
export const RoutineEditorVariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutineEditorVariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorVariablesLoad(variableName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorVariablesLoad(variableName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorVariablesApi.routineEditorVariablesLoad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routineEditorVariablesUpdate(variableName: string, runtimeVariable?: RuntimeVariable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routineEditorVariablesUpdate(variableName, runtimeVariable, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoutineEditorVariablesApi.routineEditorVariablesUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoutineEditorVariablesApi - factory interface
 * @export
 */
export const RoutineEditorVariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutineEditorVariablesApiFp(configuration)
    return {
        /**
         * Returns current state of a variable
         * @summary Get Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesLoad(variableName: string, options?: any): AxiosPromise<RuntimeVariable> {
            return localVarFp.routineEditorVariablesLoad(variableName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the value of a variable
         * @summary Update Variable Value
         * @param {string} variableName Variable Name to observe
         * @param {RuntimeVariable} [runtimeVariable] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routineEditorVariablesUpdate(variableName: string, runtimeVariable?: RuntimeVariable, options?: any): AxiosPromise<RuntimeVariable> {
            return localVarFp.routineEditorVariablesUpdate(variableName, runtimeVariable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutineEditorVariablesApi - object-oriented interface
 * @export
 * @class RoutineEditorVariablesApi
 * @extends {BaseAPI}
 */
export class RoutineEditorVariablesApi extends BaseAPI {
    /**
     * Returns current state of a variable
     * @summary Get Variable Value
     * @param {string} variableName Variable Name to observe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorVariablesApi
     */
    public routineEditorVariablesLoad(variableName: string, options?: AxiosRequestConfig) {
        return RoutineEditorVariablesApiFp(this.configuration).routineEditorVariablesLoad(variableName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the value of a variable
     * @summary Update Variable Value
     * @param {string} variableName Variable Name to observe
     * @param {RuntimeVariable} [runtimeVariable] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutineEditorVariablesApi
     */
    public routineEditorVariablesUpdate(variableName: string, runtimeVariable?: RuntimeVariable, options?: AxiosRequestConfig) {
        return RoutineEditorVariablesApiFp(this.configuration).routineEditorVariablesUpdate(variableName, runtimeVariable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpaceApi - axios parameter creator
 * @export
 */
export const SpaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanes: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/space/planes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosition: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/space/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpaceApi - functional programming interface
 * @export
 */
export const SpaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpaceApiAxiosParamCreator(configuration)
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlanes(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlanes(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SpaceApi.listPlanes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPosition(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanesPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPosition(limit, offset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SpaceApi.listPosition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SpaceApi - factory interface
 * @export
 */
export const SpaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpaceApiFp(configuration)
    return {
        /**
         * List Planes
         * @summary List Planes
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlanes(limit?: number, offset?: number, options?: any): AxiosPromise<PlanesPaginatedResponse> {
            return localVarFp.listPlanes(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * List Positions
         * @summary List Positions
         * @param {number} [limit] Maximum number of items to return
         * @param {number} [offset] Offset to start from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosition(limit?: number, offset?: number, options?: any): AxiosPromise<PlanesPaginatedResponse> {
            return localVarFp.listPosition(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpaceApi - object-oriented interface
 * @export
 * @class SpaceApi
 * @extends {BaseAPI}
 */
export class SpaceApi extends BaseAPI {
    /**
     * List Planes
     * @summary List Planes
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApi
     */
    public listPlanes(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SpaceApiFp(this.configuration).listPlanes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Positions
     * @summary List Positions
     * @param {number} [limit] Maximum number of items to return
     * @param {number} [offset] Offset to start from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpaceApi
     */
    public listPosition(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SpaceApiFp(this.configuration).listPosition(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusControlApi - axios parameter creator
 * @export
 */
export const StatusControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationStateControl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status/control-mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the system which is controlling the robot
         * @summary Set Control Mode
         * @param {RobotControlMode} [robotControlMode] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfigurationControlState: async (robotControlMode?: RobotControlMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status/control-mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotControlMode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusControlApi - functional programming interface
 * @export
 */
export const StatusControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationStateControl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotControlMode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurationStateControl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatusControlApi.getConfigurationStateControl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Set the system which is controlling the robot
         * @summary Set Control Mode
         * @param {RobotControlMode} [robotControlMode] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setConfigurationControlState(robotControlMode?: RobotControlMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotControlMode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setConfigurationControlState(robotControlMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatusControlApi.setConfigurationControlState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatusControlApi - factory interface
 * @export
 */
export const StatusControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusControlApiFp(configuration)
    return {
        /**
         * Get the system which is controlling the robot
         * @summary Get Control Mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationStateControl(options?: any): AxiosPromise<RobotControlMode> {
            return localVarFp.getConfigurationStateControl(options).then((request) => request(axios, basePath));
        },
        /**
         * Set the system which is controlling the robot
         * @summary Set Control Mode
         * @param {RobotControlMode} [robotControlMode] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfigurationControlState(robotControlMode?: RobotControlMode, options?: any): AxiosPromise<RobotControlMode> {
            return localVarFp.setConfigurationControlState(robotControlMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusControlApi - object-oriented interface
 * @export
 * @class StatusControlApi
 * @extends {BaseAPI}
 */
export class StatusControlApi extends BaseAPI {
    /**
     * Get the system which is controlling the robot
     * @summary Get Control Mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusControlApi
     */
    public getConfigurationStateControl(options?: AxiosRequestConfig) {
        return StatusControlApiFp(this.configuration).getConfigurationStateControl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the system which is controlling the robot
     * @summary Set Control Mode
     * @param {RobotControlMode} [robotControlMode] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusControlApi
     */
    public setConfigurationControlState(robotControlMode?: RobotControlMode, options?: AxiosRequestConfig) {
        return StatusControlApiFp(this.configuration).setConfigurationControlState(robotControlMode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusHealthApi - axios parameter creator
 * @export
 */
export const StatusHealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/status/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusHealthApi - functional programming interface
 * @export
 */
export const StatusHealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusHealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusHealthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatusHealthApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatusHealthApi - factory interface
 * @export
 */
export const StatusHealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusHealthApiFp(configuration)
    return {
        /**
         * Get the current health of the robot
         * @summary Get Robot Health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<StatusHealthResponse> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusHealthApi - object-oriented interface
 * @export
 * @class StatusHealthApi
 * @extends {BaseAPI}
 */
export class StatusHealthApi extends BaseAPI {
    /**
     * Get the current health of the robot
     * @summary Get Robot Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusHealthApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return StatusHealthApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeleoperationControlApi - axios parameter creator
 * @export
 */
export const TeleoperationControlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the state of teleoperation based on the provided action (\'start\' or \'stop\').
         * @summary Change Teleoperation State
         * @param {TeleopStateUpdateRequest} [teleopStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeleopState: async (teleopStateUpdateRequest?: TeleopStateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/teleop/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json; charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teleopStateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeleoperationControlApi - functional programming interface
 * @export
 */
export const TeleoperationControlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeleoperationControlApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the state of teleoperation based on the provided action (\'start\' or \'stop\').
         * @summary Change Teleoperation State
         * @param {TeleopStateUpdateRequest} [teleopStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeleopState(teleopStateUpdateRequest?: TeleopStateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeleopStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeleopState(teleopStateUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeleoperationControlApi.updateTeleopState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TeleoperationControlApi - factory interface
 * @export
 */
export const TeleoperationControlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeleoperationControlApiFp(configuration)
    return {
        /**
         * Updates the state of teleoperation based on the provided action (\'start\' or \'stop\').
         * @summary Change Teleoperation State
         * @param {TeleopStateUpdateRequest} [teleopStateUpdateRequest] Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeleopState(teleopStateUpdateRequest?: TeleopStateUpdateRequest, options?: any): AxiosPromise<TeleopStateResponse> {
            return localVarFp.updateTeleopState(teleopStateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeleoperationControlApi - object-oriented interface
 * @export
 * @class TeleoperationControlApi
 * @extends {BaseAPI}
 */
export class TeleoperationControlApi extends BaseAPI {
    /**
     * Updates the state of teleoperation based on the provided action (\'start\' or \'stop\').
     * @summary Change Teleoperation State
     * @param {TeleopStateUpdateRequest} [teleopStateUpdateRequest] Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeleoperationControlApi
     */
    public updateTeleopState(teleopStateUpdateRequest?: TeleopStateUpdateRequest, options?: AxiosRequestConfig) {
        return TeleoperationControlApiFp(this.configuration).updateTeleopState(teleopStateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeleoperationStatusApi - axios parameter creator
 * @export
 */
export const TeleoperationStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the current state of teleoperation.
         * @summary Get Teleoperation State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeleopState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/movement/teleop/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication token required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeleoperationStatusApi - functional programming interface
 * @export
 */
export const TeleoperationStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeleoperationStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the current state of teleoperation.
         * @summary Get Teleoperation State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeleopState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeleopState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeleoperationStatusApi.getTeleopState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TeleoperationStatusApi - factory interface
 * @export
 */
export const TeleoperationStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeleoperationStatusApiFp(configuration)
    return {
        /**
         * Retrieves the current state of teleoperation.
         * @summary Get Teleoperation State
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeleopState(options?: any): AxiosPromise<void> {
            return localVarFp.getTeleopState(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeleoperationStatusApi - object-oriented interface
 * @export
 * @class TeleoperationStatusApi
 * @extends {BaseAPI}
 */
export class TeleoperationStatusApi extends BaseAPI {
    /**
     * Retrieves the current state of teleoperation.
     * @summary Get Teleoperation State
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeleoperationStatusApi
     */
    public getTeleopState(options?: AxiosRequestConfig) {
        return TeleoperationStatusApiFp(this.configuration).getTeleopState(options).then((request) => request(this.axios, this.basePath));
    }
}



